import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TitleText from "../components/text/title-text/title-text"
import DescriptionText from "../components/text/description-text/description-text"

function FindUsPage() {
  return (
    <Layout>
      <SEO title="Dónde estamos" />
      <TitleText text="Dónde encontrarnos" />
      <DescriptionText
        text={`Paseo del Sol nº 2\n28880 Meco, Madrid\n\nLunes a viernes: 11:00 h - 14:00 h y 17:00 h - 20:00\nSábados: 11:00 h - 14:00 h\nHorario de verano: Cerrado los sábados`}
      />
      <iframe
        title="locationFrame"
        frameBorder="0"
        style={{ width: "100%", height: 500 }}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3031.6305758814633!2d-3.3233974241436957!3d40.549750186198985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb97e9f2afc49ddc7!2sCl%C3%ADnica+Veterinaria+Meco!5e0!3m2!1sen!2ses!4v1411136130021"
      ></iframe>
      <TitleText text="Olvídate del problema de aparcar" />
      <DescriptionText
        text={`Amplio parking para aparcar sin problemas justo al lado de nuestra clínica.`}
      />
    </Layout>
  )
}

export default FindUsPage
